$system-description-list-arrow-size: 3px;
$system-description-size: percentage(225/700);

.citroen-connectivity-system-description {
  width: $system-description-size;
  padding: ($content-gutter / 2);
  float: left;
  background: $system-description-gradient-background-fallback;
  background: $system-description-gradient-background-webkit;
  background: $system-description-gradient-background;
}

.citroen-connectivity-system-carousel {
  width: 100 - $system-description-size;
  float: left;
}

.citroen-connectivity-system-description-header {
  margin: 0.5em 0;
  font-size: 12px;
}

.citroen-connectivity-system-list {
  padding: 0;
  list-style: none;

  li {
    &:before {
      content: '';
      display: $system-description-list-arrow-display;
      margin-right: $system-description-list-arrow-size;
      vertical-align: baseline;
      border: {
        left: $system-description-list-arrow-size solid $system-description-list-arrow-color;
        top: $system-description-list-arrow-size solid transparent;
        bottom: $system-description-list-arrow-size solid transparent;
      }
    }
  }
}
