.citroen-connectivity-breadcrumbs {
  list-style: none;
  padding: 0;
  overflow: hidden;
}

.citroen-connectivity-breadcrumb {
  display: block;
  float: left;
  margin-right: $breadcrumb-gutter;

  a {
    display: block;
    min-width: $breadcrumb-width;
    height: $breadcrumb-height;
    padding: 0 1em;
    color: $breadcrumb-font-color;
    font-family: $content-button-font;
    font-size: $breadcrumb-font-size;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    line-height: $breadcrumb-height;
    border-width: $breadcrumb-border-size;
    border-style: $breadcrumb-border-style;
    border-color:  $breadcrumb-border-color;
    background-color: $breadcrumb-background-color;
    transition: $default-transition;

    &:hover {
      color: $breadcrumb-font-color-overstate;
      background-color: $breadcrumb-background-color-overstate;
    }
  }

  &.active {
    a {
    cursor: default;
    color: $breadcrumb-font-color-overstate;
    background-color: $breadcrumb-background-color-overstate;
    }
  }

}

.citroen-connectivity-breadcrumb-last {
  float: right;
  margin-right: 0;

  a {
    padding-right: 0;
    text-align: right;
    border: 0;
    &:hover {
      // padding-right: $content-gutter / 2;
      color: $breadcrumb-font-color;
      background-color: $breadcrumb-background-color;
    }
  }
}

// back link
.citoren-connectivity-content-back {
  position: relative;
  &:before {
    content: $breadcrumb-content-back;
    position: absolute;
    top: 50%;
    right: $breadcrumb-content-back-right-position;
    display: block;
    width: 1em;
    height: 1em;
    line-height: 1;
    background: url($breadcrumb-content-back-background-image) no-repeat center center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
