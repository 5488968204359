.citroen-connectivity-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
}

.citroen-connectivity-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 600px;
  padding: $content-gutter;
  font-family: $content-font;
  font-size: $content-font-size;
  color: $modal-content-font-color;
  background-color: $modal-content-backgorund-color;

  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.citroen-connectivity-modal-buttons {
  padding-top: $content-gutter;
  text-align: center;
}
.citroen-connectivity-modal-close-button {
}

//
#boxes {
  .window {
    div {
      padding-top: $content-gutter;
      text-align: center;
    }
  }
  input.button {
    @extend .citroen-connectivity-button;
  }
}
