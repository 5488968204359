.citroen-connectivity-tab-contents {
  position: relative;
  overflow: hidden;
  min-height: 350px;
  border-top: $tab-content-border-top;
}

.citroen-connectivity-tab-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: $tab-content-background-color;
}

.citroen-connectivity-tab-content-without-heading {
  padding-top: $content-gutter * 1.5;
}

.tab-content-hide {
  opacity: 0;
  -webkit-animation: fadeoutEffect 0.7s linear;
  animation: fadeoutEffect 0.7s linear;
}
.tab-content-show {
  z-index: 5;
  opacity: 1;
  -webkit-animation: fadeinEffect 0.7s linear;
  animation: fadeinEffect 0.7s linear;
}

@-webkit-keyframes fadeinEffect {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeinEffect {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-webkit-keyframes fadeoutEffect {
  from { opacity: 1; }
  to { opacity: 0; }
}
@keyframes fadeoutEffect {
  from { opacity: 1; }
  to { opacity: 0; }
}
