
$content-nav-pad: 8px;
$content-nav-height: 102px;
$content-nav-arrow-size: 15px;

.citroen-connectivity-content-nav {
  margin-bottom: ($content-nav-arrow-size + 5);
  color: $content-nav-font-color;
  background-color: $content-nav-background-color;
  border: 1px solid $content-nav-border-color;

  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}
.citroen-connectivity-content-nav-lists {

  display: block;
  @extend .citroen-connectivity-group;
  list-style: none;
  height: $content-nav-height;
  margin: 0;
  padding: 0;
}

.citroen-connectivity-content-nav-list {
  position: relative;
  width: percentage(1/3);
  height: 100%;
  padding: 0 $content-nav-pad;
  float: left;
  border-right: 1px solid $content-nav-border-color;

  &:last-child {
    border-right: 0;
  }

  &.active {
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: $content-nave-active-border;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -#{($content-nav-arrow-size + 1)};
      left: 50%;
      border: {
        top: $content-nav-arrow-size solid $content-nav-arrow-color;
        left: ($content-nav-arrow-size / 2 + 2) solid transparent;
        right: ($content-nav-arrow-size / 2 + 2) solid transparent;
      }
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  figure {
    margin: 0;
    padding: 4px 0;
    display: block;
    height: 100%;
    text-align: center;
    background: $content-nav-figure-background;
  }

  .citroen-connectivity-content-nav-figure-icon {
    position: relative;
    img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .citroen-connectivity-content-nav-figure-system {
    height: 67px + 4px + 4px;
    img {
      height: 100%;
    }
  }

  .citroen-connectivity-content-nav-system-version {
    text-align: center;
  }
  .citroen-connectivity-content-nav-system-version-help,
  .citroen-dropdown-heading,
  .citroen-dropdown {
    vertical-align: middle;
  }
  .citroen-dropdown-heading {
    margin-right: 0.25em;
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
  }
  .citroen-dropdown {
    margin-bottom: 0;
  }

  .citroen-connectivity-content-nav-system-version-help {
    display: inline-block;
    width: $content-nav-system-version-help-size;
    height: $content-nav-system-version-help-size;
    margin-left: $content-nav-system-version-help-size;
    color: $content-nav-system-version-help-color;
    text-align: center;
    text-decoration: none;
    line-height: $content-nav-system-version-help-size - ($content-nav-system-version-help-border-width * 2);
    border: $content-nav-system-version-help-border-width solid $content-nav-system-version-help-border-color;
    background-color: $content-nav-background-color;
  }

  figcaption {
    color: $content-figcaption-font-color;
  }

}
