$data-table-odd-background-color: #EDEDED;
$data-table-even-background-color: #fff;

.citroen-connectivity-data-table {
  padding: 0.5em;
  p {
    margin: 0;
  }
}
.citroen-connectivity-data-table-heading {
  margin: 0;
  padding: 1.7em 0 0;
  font-size: $tab-content-heading-size;
  color: $tab-content-heading-color;
}

.citroen-connectivity-data-table-items {
  padding: 0;
  list-style: none;
}

.citroen-connectivity-data-table-item {
  position: relative;
  display: block;
  padding: 0.25em 1.5em 0.25em 0.5em;
  background-color: $data-table-even-background-color;

  &:nth-of-type(odd) {
    background-color: $data-table-odd-background-color;
  }

  img {
    position: absolute;
    top: 50%;
    right: 0.5em;
    display: block;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
