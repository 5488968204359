.citroen-connectivity-carousel-content {
  overflow: hidden;
  padding: $carousel-padding;
  background: $carousel-gradient-background-fallback;
  background: $carousel-gradient-background-webkit;
  background: $carousel-gradient-background;

  .citroen-connectivity-carousel-heading {
    margin: $content-gutter 0 ($content-gutter * 2);
    text-align: center;
    font-size: $content-carousel-header-font-size;
    color: $content-carousel-header-font-color;
  }

  figure {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .citoren-connectivity-carousel-figcaption {
    padding: 5px;
    color: $content-figcaption-font-color;
    line-height: 1.2;
  }

}


.citroen-connectivity-carousel-phones {
  padding-bottom: $content-gutter;
}
