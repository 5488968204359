.citroen-dropdown-section {
  position: absolute;
  right: 0;
  top: 0;
  padding: ($dropdown-pad / 2);

  .citroen-dropdown-select {
    display: block;
  }
}

.citroen-dropdown,
.citroen-dropdown-heading {
  display: inline-block;
}

.citroen-dropdown-heading {
  width: 3.7em;
  margin: 0;
  color: $dropdown-heading-color;
  line-height: $dropdown-button-height;
  font-weight: 400;
}

.citroen-dropdown {
  margin-bottom: 0.5em;
  position: relative;
  width: $dropdown-width;
}


.citroen-dropdown-button {
  position: relative;
  display: block;
  width: 100%;
  height: $dropdown-button-height;
  padding: 0 ($dropdown-pad * 2) 0 ($dropdown-pad / 2);
  color: $dropdown-font-color;
  text-align: left;
  line-height: $dropdown-button-height;
  border: 1px solid $dropdown-button-border;
  background-color: $dropdown-button-background;
  outline: none;
  overflow: hidden;
  // border-radius: $default-border-radius;
  cursor: pointer;

  &:after {
    content: $dropdown-button-arrow-content;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 22px;
    width: 22px;
    height: 22px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: $dropdown-button-arrow;
    background-size: $dropdown-button-arrow-size;
    // top: 5px;
    // right: 6px;
    // border: {
    //   top: 7px solid $content-background;
    //   left: 4px solid transparent;
    //   right: 4px solid transparent;
    // }
  }
}

.citroen-dropdown-menu {
  display: none;
  opacity: 0;

  position: absolute;
  z-index: 5;
  top: $dropdown-button-height;
  left: 0;
  right: 0;
  margin: 0 auto 1em;
  padding: 0;
  width: 100%;
  max-height: 49 * 4px;
  overflow-y: auto;
  background-color: $dropdown-button-background;

  border: {
    left: 1px solid $dropdown-button-border;
    right: 1px solid $dropdown-button-border;
    bottom: 1px solid $dropdown-button-border;
  }
  // border-radius: 0 0 $default-border-radius $default-border-radius;
  list-style: none;

  &.citroen-dropdown-visible {
    display: block;
    opacity: 1;
  }
}

.citroen-dropdown-item {

  a {
    display: block;
    padding: 0 $dropdown-pad;
    overflow: hidden;
    color: $dropdown-font-color;
    height: $dropdown-item-height;
    line-height:$dropdown-item-height;
    text-decoration: none;
    text-align: left;
    background-color: $dropdown-button-background;
    will-change: color, background-color;
    transition: $default-transition;
    &:hover {
      color: $content-background;
      background-color: $dropdown-button-background-overstate;
    }
  }
}
