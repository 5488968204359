
$content-width: 1000px;
$content-gutter: 20px;

$content-font-size: 12px;

$content-button-height: 45px;

$default-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);


$tablet: 768px;
$mobile: 440px;
