$carousel-item-max-width: 200px;
$carousel-dot-background-color: #EFEFEF;
$carousel-dot-background-color-overstate: #CCCCCC;

.frame-center {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  border: $frame-center-border;
  box-shadow: $frame-center-box-shadow;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.citroen-connectivity-system-carousel {
  padding: $carousel-system-padding;

  .citroen-connectivity-system-description-header {
    margin-left: $content-gutter;
  }
}


.citroen-connectivity-carousel {
  position: relative;
}

.citroen-connectivity-carousel-item {
  max-width: $carousel-item-max-width;
  display: block;
  // margin: 0 auto;
  padding: ($content-gutter / 2) $content-gutter;
  text-align: center;

  opacity: 0.4;

  figure {
    margin: 0;
  }

  figcaption {
    margin-top: 0.5em;
    color: $content-figcaption-font-color;
  }
}



/* carousel phone */
// .citroen-connectivity-system-carousel,
// .citroen-connectivity-carousel-phones,
// .citroen-connectivity-carousel-cars {
//   .citroen-connectivity-carousel-item {
//     opacity: 0.4;
//   }
// }

.citroen-connectivity-carousel-phones,
.citroen-connectivity-carousel-cars {
  .citroen-connectivity-carousel-item {

    img {
      display: inline-block;
      max-width: 100px;
      margin: 0 auto;
    }
  }
}

.citroen-carousel-frame-center {
  @extend .frame-center;
  width: 130px;
  height: 100%;

  .citroen-connectivity-carousel-cars & {
    height: 83.5%;
  }
}

.owl-item.active.selected,
.owl-item.active.center {
  .citroen-connectivity-carousel-item {
    opacity: 1;
  }
}

// dots
.owl-dots {
  margin: $content-gutter 0 ($content-gutter / 2);
  width: 100%;
  height: 10px;
  overflow: hidden;
}
.owl-dot {
  display: block;
  float: left;
  height: 100%;
  span {
    display: block;
    width: 100%;
    height: 100%;
    background-color: $carousel-dot-background-color;
    transition: $default-transition;
  }
  &.active,
  &:hover {
    span {
      background-color:  $carousel-dot-background-color-overstate;
    }
  }
}
