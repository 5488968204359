.citroen-connectivity-content {
  font-family: $content-font;
  font-size: $content-font-size;
  color: $content-font-color;
  background-color: $content-background;

  box-sizing: border-box;

  p {
    line-height: 1.6;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
}

.citroen-connectivity-content-introduction {
  color: $content-introduction-font-color;
}

.citroen-connectivity-wrapper {
  max-width: $content-width;
  width: $content-width;
  margin: 0 auto;
  padding: $content-gutter;
}



.citroen-connectivity-content-header {
  position: relative;
  h1 {
    margin-left: -#{$content-header-padding};
    padding-left: $content-header-padding;
    color: $content-header-color;
    font-family: $content-header-font;
    font-size: $content-header-size;
    font-style: normal;
    font-weight: normal;
    text-transform: $content-header-text-transform;
    border-left: $content-header-border;
  }
}

.citroen-connectivity-print-button {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 0;
  background: #fff url('../images/print-icon.gif') no-repeat center center;
}

.citroen-connectivity-content-buttons {
  min-height: $content-button-height + 2;
  padding: ($content-gutter / 2) 0;
  text-align: center;
  background: url($content-nextgen-logo) no-repeat right center;
}
