$content-button-width: 160px;
// $content-button-height: 45px;

.citroen-connectivity-button {
  position: relative;
  display: inline-block;
  width: $content-button-width;
  height: $content-button-height;
  font-family: $content-button-font;
  font-size: $content-button-font-size;
  color: $content-button-font-color;
  line-height: $content-button-height;
  text-decoration: none;
  text-transform: uppercase;
  text-align: $content-button-text-align;
  text-indent: $content-button-text-indent;
  border: 1px solid $content-button-border-color;
  background-color: $content-button-background-color;
  outline: none;

  transition: $default-transition;

  &:hover {
    color: $content-button-font-color-overstate;
    background-color: $content-button-background-color-overstate;

    &:after {
      background: $content-button-arrow-overstate;
    }
  }

  &:after {
    content: $content-button-arrow-content;
    position: absolute;
    display: block;
    top: 50%;
    right: ($content-gutter / 2);
    width: $content-button-arrow-width;
    height: $content-button-arrow-height;
    background: $content-button-arrow;
    background-size: 13px 13px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

}
