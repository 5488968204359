.citroen-connectivity-start-guide-header {
  padding: 0.5em;
  @extend .citroen-connectivity-group;
}
.citroen-connectivity-start-guide-header-item {
  display: block;
  width: 50%;
  float: left;
  box-sizing: border-box;

  figure,
  p {
    display: table-cell;
    vertical-align: middle;
  }
  figure {
    margin: 0;
    padding: 0 10px;
  }

  img {
    display: block;
    height: 50px;
    width: auto;
  }
}


.citroen-connectivity-instruction-guide {
  margin: 0 0.5em;
  padding-bottom: $content-gutter;
  border-bottom: 1px solid $stripes-odd-color;
}

.citroen-connectivity-instruction-heading {
  margin: 0;
  padding: 1.7em 0 0.7em;
  color: $tab-content-heading-color;
}

.citroen-connectivity-instruction {
  padding: 0.25em 0;
}

.citroen-connectivity-instruction-image,
.citroen-connectivity-instruction-text {
  margin: 0;
  display: table-cell;
  vertical-align: top;
}

.citroen-connectivity-instruction-image {
  width: 84px;

  figure {
    margin: 0;
  }

  img {
   max-width: 100%;
   height: 100%;
   margin: 0 auto;
   display: block;
   border: 0;
  }

}

[data-image-kind="car"] {
  height: 27px;
}
[data-image-kind="phone"] {
  height: 24px;
}

.citroen-connectivity-instruction-steps {
  margin: 0;
  padding: 0;
  list-style: none;
   li {
     padding: 0.25em;
   }
}
