.citroen-connectivity-features {


  .citroen-dropdown-heading {
    width: auto;
    color: $content-section-font-color;
  }

  .citroen-connectivity-features-dropdown {
    float: right;
  }

}

.citroen-connectivity-features-heading {
  @extend .citroen-connectivity-group;
}

.citroen-connectivity-features-system-version {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 0;
  padding: 0 0.5em;
  line-height: 1;
  color: $content-section-font-color;
  background-color: $features-system-version-background-color;

}


// tick lists
.citroen-connectivity-features-tick-lists {
  margin: 0.25em 0;
  padding: 0;
  list-style: none;
  @extend .citroen-connectivity-group;

  li {
    width: percentage(1/3);
    float: left;
    padding: 0.25em 1em;
    box-sizing: border-box;

    img {
      display: inline-block;
      margin-right: 0.15em;
      vertical-align: middle;
    }
  }
}
