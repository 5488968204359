.citroen-connectivity-group {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.citroen-connectivity-table {
  display: table;
  vertical-align: top;
}

@mixin citroen-header($width, $height, $margin) {
  &:after {
    content: '';
    display: block;
    width: $width;
    height: $height;
    margin-top: $margin;
    // background-color: $citroen-red;
  }
}

@function em($px, $basepx: 14) {
  @return #{$px / $basepx}em;
}

.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }


.citroen-connectivity-newline {
  display: block;
}
