.citroen-connectivity-information-content {
  position: relative;

  color: $content-section-font-color;
  background: $information-content-gradient-background-fallback;
  background: $information-content-gradient-background-webkit;
  background: $information-content-gradient-background;

  @extend .citroen-connectivity-group;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}
