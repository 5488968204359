$scrollbar-thumb-width: 12px;
$scrollbar-thumb-height: 40px;
$scrollbar-track-color: #C9C9C9;
$scrollbar-thumb-color: #808080;

[data-citroen-modules="scrollbar"] {
  width: 100%;
  position: relative;
}

[data-citroen-modules="scrollbar"] .viewport {
  height: 300px;
  overflow:hidden;
  position:relative;
  margin-right: ($scrollbar-thumb-width * 2);
  padding-bottom: $content-gutter;
}

[data-citroen-modules="scrollbar"] .overview {
  list-style:none;
  position:absolute;
  left: 0;
  top: 0;
  right: 0;
}

[data-citroen-modules="scrollbar"] .scrollbar {
  // background:transparent url(images/bg-scrollbar-track-y.png) no-repeat 0 0;
  position:absolute;
  background-position:0 0;
  right: 0;
  top: 0;
  width: $scrollbar-thumb-width;
}

[data-citroen-modules="scrollbar"] .track {
  height:100%;
  width: $scrollbar-thumb-width;
  position:relative;
  padding:0 1px;
  background-color: $scrollbar-track-color;
}

[data-citroen-modules="scrollbar"] .thumb {
    // background:transparent url(images/bg-scrollbar-thumb-y.png) no-repeat 50% 100%;
  cursor:pointer;
  overflow:hidden;
  position:absolute;
  top: 0;
  left: 0;
  display: block;
  height: $scrollbar-thumb-height;
  width: $scrollbar-thumb-width;
  background-color: $scrollbar-thumb-color;
}

[data-citroen-modules="scrollbar"] .thumb .end {
  // background:transparent url(images/bg-scrollbar-thumb-y.png) no-repeat 50% 0;
  overflow:hidden;
  height: $scrollbar-thumb-height;
  width: $scrollbar-thumb-width;
}

[data-citroen-modules="scrollbar"] .disable {
  display:none;
}

.noSelect {
  user-select:none;
  -o-user-select:none;
  -moz-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
}
