$tab-item-height: 50px;

.citroen-connectivity-tab-section {
  color: $tab-section-font-color;
  background-color: #fff;
}

.citroen-connectivity-tab-nav {
  ul {
    @extend .citroen-connectivity-group;
    margin: 0;
    padding: 0;
  }
}

.citroen-connectivity-tab-nav-item {
  display: block;
  width: percentage(1/4);
  float: left;
  padding-right: $tab-item-gutter;

  &:last-of-type {
    padding-right: 0;
  }

  a {
    position: relative;
    display: block;
    min-height: $tab-item-height;
    color: $content-section-font-color;
    font-size: $tab-font-size;
    text-align: center;
    text-decoration: none;
    border-top: $tab-border-top;
    background-color: $tab-background-color;
    transition: $default-transition;

    &.active {
      cursor: default;
      color: $tab-active-font-color;
      font-weight: $tab-active-font-weight;
      background-color: $tab-active-background-color;
    }

    &:hover {
      background-color: $tab-active-background-color;
    }
  }

  .vertically-align {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
