$carousel-nav-width: 50px;
$carousel-nav-height: 50px;

// citroen blue theme
.owl-nav {
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 14%;
    display: block;
    width: $carousel-nav-width;
    height: $carousel-nav-height;
    font-size: 0;
    border: 2px solid #afadc4;
    background: url('../images/theme-blue/icon-arrow-left.svg') no-repeat center center;
    background-size: 15px 15px;
    -webkit-transition: $default-transition;
    transition: $default-transition;

    .citroen-connectivity-slides-phones & {
      top: 40px;
      margin-top: 0;
    }

    &.disabled {
      display: none;
    }
  }

  .owl-prev {
    left: -#{$content-gutter * 2};
    background: $content-background url('../images/theme-blue/icon-arrow-left.svg') no-repeat center center;
    .citroen-connectivity-system-carousel & {
      left: -#{$content-gutter * 3};
    }
    &:hover {
      background: $content-button-background-color url('../images/theme-blue/icon-arrow-left-active.svg') no-repeat center center;;
    }
  }
  .owl-next {
    right: -#{$content-gutter * 2};
    background: $content-background url('../images/theme-blue/icon-arrow-right.svg') no-repeat center center;
    .citroen-connectivity-system-carousel & {
      right: -#{$content-gutter * 3};
    }
    &:hover {
      background: $content-button-background-color url('../images/theme-blue/icon-arrow-right-active.svg') no-repeat center center;;
    }
  }
}

.owl-dots {
  overflow: hidden;
}
.owl-dot {
  float: left;
  width: 30px;
  height: 10px;
  background-color: whitesmoke;
  &.active {
    background-color: hotpink;
  }
}

.citroen-carousel-five-cars .owl-nav.disabled,
.citroen-carousel-five-cars .owl-dots.disabled {
  display: block !important;
}
