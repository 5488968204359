$content-font: 'Ubuntu', sans-serif;
$content-button-font: 'citroenregular', sans-serif;
$content-header-font: 'citroenbold', serif;

$content-theme-primary-color: #017e98; // blue
$content-theme-secondary-color: #c9dd03; // green

// logo
$content-nextgen-logo: '../images/theme-blue/nextgen-logo.png';

$content-font-size: 12px;
$content-font-color: #696969;
$content-background: #fff;

// content header
$content-header-color: $content-theme-primary-color;
$content-header-text-transform: capitalize;
$content-header-size: 24px;
$content-header-padding: $content-gutter * 1.5;
$content-header-border: 3px solid $content-theme-secondary-color;
// introduction font
$content-introduction-font-color: $content-font-color;

$content-section-font-color: $content-font-color;

// button
$content-button-font-size: 18px;
$content-button-font-color: #fff;
$content-button-border-color: #afadc3;
$content-button-background-color: $content-button-border-color;
$content-button-font-color-overstate: $content-button-border-color;
$content-button-background-color-overstate: $content-button-font-color;
$content-button-text-align: left;
$content-button-text-indent: 16px;
// button arrow
$content-button-arrow-content: '';
$content-button-arrow-width: 0.75em;
$content-button-arrow-height: 0.75em;
$content-button-arrow: url('../images/theme-blue/icon-button-arrow-right.svg') no-repeat center center;
$content-button-arrow-overstate: url('../images/theme-blue/icon-arrow-right.svg') no-repeat center center;

// breadcrumb
$breadcrumb-width: 150px;
$breadcrumb-height: 50px;
$breadcrumb-gutter: 7px;
$breadcrumb-font-size: 13px;
$breadcrumb-font-color: #868686;
$breadcrumb-font-color-overstate: #fff;
$breadcrumb-border-size: 2px 0 0 0;
$breadcrumb-border-style: solid;
$breadcrumb-border-color: $content-theme-primary-color;
$breadcrumb-background-color: $content-background;
$breadcrumb-background-color-overstate: $content-theme-primary-color;
// breadcrumb back
$breadcrumb-content-back: '';
$breadcrumb-content-back-right-position: 4em;
$breadcrumb-content-back-background-image: '../images/theme-blue/icon-arrow-left.svg';

// dropdown
$dropdown-width: 140px;
$dropdown-item-height: 2.8em;
$dropdown-pad: 10px;
$dropdown-font-color: $content-font-color;
$dropdown-heading-color: #696969;
$dropdown-button-border: #ccc;
$dropdown-button-background: #fff;
$dropdown-button-height: 24px;
$dropdown-button-arrow-content: '';
$dropdown-button-arrow: url('../images/theme-blue/icon-dropdown-arrow-down.svg') no-repeat center center;
$dropdown-button-arrow-size: 9px 9px;
$dropdown-button-background-overstate: $content-theme-primary-color;

// figcaption
$content-figcaption-font-color: $content-font-color;
$content-carousel-header-font-color: $content-section-font-color;
$content-carousel-header-font-size: 14px;

// content navigation
$content-nav-font-color: $content-font-color;
$content-nav-background-color: #fff;
$content-nav-border-color: $content-font-color;
$content-nav-arrow-color: $content-font-color;
$content-nav-figure-background: #fff;
$content-nave-active-border: 3px solid $content-theme-secondary-color;

// information content
$information-content-gradient-top: #E6E4E4;
$information-content-gradient-bottom: #fff;
$information-content-gradient-background-fallback: #fff;
$information-content-gradient-background-webkit: #fff;
$information-content-gradient-background: #fff;
// carousel phone slightly different gradient
$carousel-padding: 0 ($content-gutter * 2);
$carousel-gradient-bottom: #fff;
$carousel-gradient-background-fallback: #fff;
$carousel-gradient-background-webkit: #fff;
$carousel-gradient-background: #fff;
// carousel system
$carousel-system-padding: ($content-gutter / 2) ($content-gutter * 3);

// frame center
$frame-center-border: 3px solid $content-theme-secondary-color;
$frame-center-box-shadow: none;

// table
$stripes-odd-color: #EDEDED;

// tab
$tab-item-gutter: 7px;
$tab-font-size: 11px;
$tab-section-font-color: $content-font-color;
$tab-border-top: 2px solid $content-theme-secondary-color;
$tab-background-color: $content-background;
$tab-active-font-color: $content-background;
$tab-active-font-weight: 700;
$tab-active-background-color: $content-theme-secondary-color;
$tab-content-border-top: none;

// features
$features-system-version-background-color: #ACD6FF;

// tab content
$tab-content-background-color: #fff;
$tab-content-heading-size: 14px;
$tab-content-heading-color: #000;

// system description
$system-description-gradient-top: #fff;
$system-description-gradient-bottom: #fff;
$system-description-gradient-background-fallback: #fff;
$system-description-gradient-background-webkit: #fff;
$system-description-gradient-background: #fff;
$system-description-list-arrow-display: none;
$system-description-list-arrow-color: #E62339;

// content nav system version help
$content-nav-system-version-help-size: 24px;
$content-nav-system-version-help-color: $content-theme-secondary-color;
$content-nav-system-version-help-border-width: 2px;
$content-nav-system-version-help-border-color: $content-theme-secondary-color;

// carousel
$carousel-nav-arrow-size: 10px;
$carousel-nav-arrow-color: #282859;

// modal window
$modal-content-font-color: #646464;
$modal-content-backgorund-color: #fff;
