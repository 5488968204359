// blue theme
// Citroen
@font-face {
  font-family: 'citroenbold';
  src: url('../fonts/Citroen/citroen_bold-webfont.eot');
  src: url('../fonts/Citroen/citroen_bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Citroen/citroen_bold-webfont.woff') format('woff'),
       url('../fonts/Citroen/citroen_bold-webfont.ttf') format('truetype'),
       url('../fonts/Citroen/citroen_bold-webfont.svg#citroenbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
// Citroen regular
@font-face {
  font-family: 'citroenregular';
  src: url('../fonts/Citroen/citroen_regular-webfont.eot');
  src: url('../fonts/Citroen/citroen_regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Citroen/citroen_regular-webfont.woff') format('woff'),
       url('../fonts/Citroen/citroen_regular-webfont.ttf') format('truetype'),
       url('../fonts/Citroen/citroen_regular-webfont.svg#citroenregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
// Ubuntu font-family: 'Ubuntu', sans-serif;
@import url('//fonts.googleapis.com/css?family=Ubuntu:400,700');



// Default theme
// DS Title
@font-face {
  font-family: 'DSTitleWebMedium-Regular';
  src: url('../fonts/DS-Title-Web/DSTitleWebMedium-Regular.eot');
  src: url('../fonts/DS-Title-Web/DSTitleWebMedium-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/DS-Title-Web/DSTitleWebMedium-Regular.woff') format('woff'),
       url('../fonts/DS-Title-Web/DSTitleWebMedium-Regular.ttf') format('truetype'),
       url('../fonts/DS-Title-Web/DSTitleWebMedium-Regular.svg#DSTitleWebMedium-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Roboto regular
@font-face {
  font-family: 'robotoregular';
  src: url('../fonts/Roboto/Roboto-Regular-webfont.eot');
  src: url('../fonts/Roboto/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Roboto/Roboto-Regular-webfont.woff') format('woff'),
       url('../fonts/Roboto/Roboto-Regular-webfont.ttf') format('truetype'),
       url('../fonts/Roboto/Roboto-Regular-webfont.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Roboto medium
@font-face {
  font-family: 'robotomedium';
  src: url('../fonts/Roboto/Roboto-Medium-webfont.eot');
  src: url('../fonts/Roboto/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Roboto/Roboto-Medium-webfont.woff') format('woff'),
       url('../fonts/Roboto/Roboto-Medium-webfont.ttf') format('truetype'),
       url('../fonts/Roboto/Roboto-Medium-webfont.svg#robotomedium') format('svg');
  font-weight: normal;
  font-style: normal;
}
