$slider-scrollbar-background-color: #EFEFEF;
$slider-scrollbar-handle-color: #CCCCCC;

$slider-height: 100px;
$slider-item-width: 130px;

.sly-frame-wrapper {
  position: relative;
}

.sly-frame-center {
  @extend .frame-center;
  width: $slider-item-width;
  height: $slider-height;
}

.sly-frame {
  width: 100%;
  height: $slider-height;
  padding: 0;

  .sly-slidee {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
  }

  li {
    float: left;
    margin: 0 5px 0 0;
    padding: 0;
    width: $slider-item-width;
    height: 100%;
    opacity: 0.4;

    &.active {
      opacity: 1;
    }

  }
}

// scrollbar
.sly-scrollbar {
  width: 100%;
  margin-top: $content-gutter;
  height: 10px;
  background-color: $slider-scrollbar-background-color;

  .sly-handle {
    width: 100px; /* overriden if dynamicHandle: 1 */
    height: 100%;
    background-color: $slider-scrollbar-handle-color;
  }
}

// next and previous
.sly-controls {
}
.sly-control-button {
  position: absolute;
  top: 0;
  height: $slider-height;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;

  &:before {
    content: '';
    display: block;
    border: {
      top: ($carousel-nav-arrow-size / 2) solid transparent;
      bottom: ($carousel-nav-arrow-size / 2) solid transparent;
    }
  }
}

.sly-control-next {
  right: -#{$carousel-nav-arrow-size * 3};
  &:before {
    border-left: $carousel-nav-arrow-size solid $carousel-nav-arrow-color;
  }
}

.sly-control-prev {
  left: -#{$carousel-nav-arrow-size * 3};
  &:before {
    border-right: $carousel-nav-arrow-size solid $carousel-nav-arrow-color;
  }
}
